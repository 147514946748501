import React from 'react'
import "./index.css"

const Footer = () => {
  return (
    <div className='footer-container'>
    <p className='footer-text'>All Rights Reserved &copy; Copyright, {new Date().getFullYear()}, Ahmet Can Yaman.</p>
    </div>
  )
}

export default Footer