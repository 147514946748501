import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Login from './Login';
import UserProvider from './userContext';
import App from './App.jsx'
import Layout from './Layout';
import ErrorPage from './ErrorPage.jsx';

const router = createBrowserRouter([
  {
    path: '/',
    element: <UserProvider> <Layout/> </UserProvider>,
    errorElement: <ErrorPage />,
    children:[
      {index: true, element: <Login />},
      {path: 'login', element: <Login />},
      {path: 'predictions', element: <App />}
    ]  
  }
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <RouterProvider router={router}/>
  </React.StrictMode>
);