import React from 'react'
import {Link} from 'react-router-dom'
import { MdQueryStats } from 'react-icons/md';
import Button from 'react-bootstrap/Button';


function ErrorPage() {
  return (
    <section className='errorpage-section'>
      <div className='header-logo-container'>
                <MdQueryStats className='header-icons' />
                <h1 className='header-title'>Mycroft <br /> Stats</h1>
            </div>
      <div className='errorpage-container'>
        <Link to="/" className='errorpage-button'>
          <Button variant="outline-primary">Go Back Home!</Button>
        </Link>
        <h2 className='errorpage-error'>Page Not Found!</h2>
      </div>
    </section>
  )
}

export default ErrorPage