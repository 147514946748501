import React, { useEffect, useContext } from 'react';
import "./index.css";
import Info from "./Info.jsx";
import Phase from './Phase.jsx';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './userContext.jsx';

const App = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const token = currentUser?.token;

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  return (
    <div className='app-main-container'>
      <Phase />
      <Info />
    </div>
  );
};

export default App;
