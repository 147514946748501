import React, { useState, useContext } from 'react';
import './index.css'; // Assuming you create a Login.css file for styling
import Button from 'react-bootstrap/Button'; // Assuming you use react-bootstrap for the Button component
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import { RiEyeOffFill, RiEyeFill } from "react-icons/ri";

import { UserContext } from './userContext.jsx';

const Login = () => {
    const [passOpen, setPassOpen] = useState(false);

    const [userData, setUserData] = useState({
        username: '',
        password: ''
    });

    const [error, setError] = useState("");
    const navigate = useNavigate();

    const { setCurrentUser } = useContext(UserContext);

    const handleInputChange = (event) => {
        setUserData(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const passOpenHandler = () => {
        setPassOpen(prevState => !prevState);
    };

    const loginUser = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/login`, userData);
            const user = response.data;
            setCurrentUser(user);
            navigate("/predictions");
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    return (
        <div className='login-main-container'>
            {error && <p className='login-error-container'>{error}</p>}
            <h1 style={{ marginBottom: "10px" }}>LOGIN</h1>
            <div className='login-container'>
                <form className='form login__form' onSubmit={loginUser}>
                    <input
                        type='text'
                        placeholder='Username'
                        className='login-input'
                        autoFocus
                        name='username'
                        value={userData.username}
                        onChange={handleInputChange}
                    />
                    <div className='password-input-container'>
                        <input
                            type={passOpen ? 'text' : 'password'}
                            placeholder='Password'
                            name='password'
                            className='login-input'
                            value={userData.password}
                            onChange={handleInputChange}
                        />
                        <div onClick={passOpenHandler} className='eye-icon'>
                            {passOpen ? <RiEyeFill style={{}}/> : <RiEyeOffFill style={{}}/>}
                        </div>
                    </div>
                    <Button type='submit' variant="outline-primary">Login</Button>
                </form>
            </div>
        </div>
    );
};

export default Login;
