import React from 'react';
import './index.css';
import { MdQueryStats } from 'react-icons/md';
import { RxExit } from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {useContext} from 'react'
import {UserContext} from "./userContext.jsx";
import { useLocation } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const {currentUser,setCurrentUser} = useContext(UserContext);
    const location = useLocation();

    
    return (
        <header className='header-main-container'>
            <div className='header-logo-container' onClick={() => {
                window.location.reload();
            }}>
                <MdQueryStats className='header-icons' />
                <h1 className='header-title'>Mycroft <br /> Stats</h1>
            </div>
            {currentUser != null ? (<div className='header-menu-container'>
                <RxExit className='header-icons header-icons-hover' onClick={() => {
                    setCurrentUser(null);
                    navigate("/login");
                }} />

            </div>) : null}
        </header>
    );
};

export default Header;
