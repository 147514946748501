import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCircle } from "react-icons/fa";

const Phase = () => {
  const [phases, setPhases] = useState([]);
  const [phaseImportance, setPhaseImportance] = useState("2");
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/phase/getphase`);
        
        setPhases(response.data);
        if (response.data.length > 0) {
          setPhaseImportance(response.data[0].importance);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Initial fetch

    const intervalId = setInterval(fetchData, 1000); // Fetch every 1 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const importanceHandler = (phaseImportance) => {
    if (phaseImportance === "1") {
      return "orange";
    }
    if (phaseImportance === "0") {
      return "green";
    }
    return "red";
  }



  return (
        <div className='main-phase-container'> 
        {phases.length > 0 ? (
         <div>
          <div className='phase-container'>{phases[0].phase}</div>
          <div className='phase-container' style={{ color: importanceHandler(phaseImportance) }}>Status: <FaCircle /></div>
         </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>

  );
};

export default Phase;
