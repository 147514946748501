import React, { useEffect, useState } from 'react';
import './index.css';
import { helix } from 'ldrs'
import {useNavigate} from "react-router-dom";
import { HiQuestionMarkCircle } from "react-icons/hi2";
import { IoCheckmarkCircle, IoCloseCircleSharp } from "react-icons/io5";
import { useContext } from 'react';
import {UserContext} from "./userContext.jsx"


helix.register()

const Info = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const [validationValues, setValidationValues] = useState();
  const navigate = useNavigate()


  const {currentUser} = useContext(UserContext);
  const token = currentUser?.token;

  useEffect(() => {
    if(!token){
      navigate('/login')
    }
  }, [])


  useEffect(() => {
    const fetchPredictData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/predict/getallpredicts`,
          {withCredentials: true, headers:{Authorization: `Bearer ${token}`}});
        const validationResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/predict/getvalidations`,
          {withCredentials: true, headers:{Authorization: `Bearer ${token}`}}
        );
        if (!response.ok && !validationResponse.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        const resultValid = await validationResponse.json();

        // Update or initialize expandedItems based on fetched data
        setExpandedItems(prevExpandedItems => {
          // Ensure that prevExpandedItems length matches result length
          const newExpandedItems = [...prevExpandedItems];
          result.forEach((_, index) => {
            if (prevExpandedItems[index] === undefined) {
              newExpandedItems[index] = false; // Initialize only if not already defined
            }
          });
          return newExpandedItems;
        });

        setValidationValues(resultValid);
        setData(result);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchPredictData();

    const intervalId = setInterval(fetchPredictData, 10000); // Fetch every 10 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const toggleAccordion = (index) => {
    setExpandedItems(prevExpandedItems => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  if (loading) {
    return (
      <div className='loader-container'>
        <l-helix size="45" speed="2.5" color="#011F98"></l-helix>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  const predValidator = (validValue) => {
    if(validValue === "1"){
      return <IoCheckmarkCircle style={{color:"lightgreen", marginRight:"10px"}}/>
    }
    else{
      return <IoCloseCircleSharp style={{color:"red", marginRight:"10px"}}/>
    }
      
    
  }


  function countOccurrences(array, value) {
    const count = array.reduce((acc, currentValue) => {
      // Check if currentValue is equal to the specified value
      return currentValue === value ? acc + 1 : acc;
    }, 0);
    return count;
  }

  return (
  <div className="info-container">
  
    <div className='main-valid-container'>
      <div>
            <h2 style={{fontSize:"3.4vh", textDecoration:"underline", marginTop:"1vh"}}>Statistics</h2>
        </div>
      <div className='validation-container'>
              <p>XGBOOST  : {countOccurrences(validationValues.xgboostValid,"1")+"/"+ data.length}</p>
              <p style={{marginTop:"1vh"}}>RNN-LSTM : {countOccurrences(validationValues.rnnValid,"1")+"/"+ data.length}</p>
              <p style={{marginTop:"1vh"}}>ARIMA : {countOccurrences(validationValues.arimaValid,"1")+"/"+ data.length}</p>
              <p style={{marginTop:"1vh"}}>STL : {countOccurrences(validationValues.regressorsValid,"1")+"/"+ data.length}</p>
            <div style={{fontSize:"30px"}}>{data.length } <span style={{fontSize:"20px"}}>days</span></div>
      </div>
    </div>
    


      {data.map((item, index) => (
        <div key={item._id} className="info-block">
          <div style={{ backgroundColor: item.xgboost.prediction === "1" ? "green" : "red" }} className="accordion-header" onClick={() => toggleAccordion(index)}>
            <h2>{new Date(item.date).toLocaleDateString()}</h2>
          </div>
          <div style={{ backgroundColor: item.xgboost.prediction === "1" ? "green" : "red" }} className={`accordion-details ${expandedItems[index] ? 'expanded' : ''}`}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className='info-print-container'>
                <div className='xgboost-print-container'>
                  <div className='pred-header-container'>
                    <h3 style={{color:"antiquewhite"}}>XGBoost:</h3>
                      <h3>
                         {index === 0 ? <HiQuestionMarkCircle style={{color:"orange", marginRight:"10px"}}/>  :  predValidator(validationValues.xgboostValid[index-1])}
                      </h3>
                  </div>
                  
                  <hr style={{color:"antiquewhite"}}/>
                  <br />
                      <h4>RSME  : {item.xgboost.RSME}</h4>
                      <h4>MAPE  : {item.xgboost.MAPE}</h4>
                      <h4>Next Day Value  : {item.xgboost.nextDayValue}</h4>
                      <h4>Current Value  : {item.xgboost.currentDayValue}</h4>
                      <h4>Prediction  : {item.xgboost.prediction}</h4>
                      <h4>Accuracy  : {item.xgboost.accuracy}</h4>
                      <h4>Reliability  : {item.xgboost.reliability}</h4>
                </div>
                <div className='rnn-print-container'>
                <div className='pred-header-container'>
                    <h3 style={{color:"antiquewhite"}}>RNN:</h3>
                    <h3>
                      {index === 0 ? <HiQuestionMarkCircle style={{color:"orange", marginRight:"10px"}}/> :  predValidator(validationValues.rnnValid[index-1])}
                    </h3>
                   
                  </div>
                  <hr style={{color:"antiquewhite"}}/>
                  <br />
                      <h4>trainSQE  : {item.rnn.trainSQE}</h4>
                      <h4>testSQE  : {item.rnn.testSQE}</h4>
                      <h4>Next Day Value  : {item.rnn.nextDayValue}</h4>
                      <h4>Current Value  : {item.rnn.currentDayValue}</h4>
                      <h4>Prediction  : {item.rnn.prediction}</h4>
                      <h4>Accuracy  : {item.rnn.accuracy}</h4>
                      <h4>Reliability  : {item.rnn.reliability}</h4>
                </div>
                <div className='regressor-print-container'>
                <div className='pred-header-container'>
                    <h3 style={{color:"antiquewhite"}}>STL:</h3>
                  </div>
                  <hr style={{color:"antiquewhite"}}/>
                  <br />
                      <h4>MSE  : {item.regressors.mean_scored_error}</h4>
                      <h4>Prediction  : {item.regressors.prediction}</h4>
                      <h4>Next Day Value  : {item.regressors.nextDayPrediction}</h4>
                      <h4>Current Value  : {item.regressors.currentDayValue}</h4>
                </div>
                <div className='arima-print-container'>
                <div className='pred-header-container'>
                    <h3 style={{color:"antiquewhite"}}>ARIMA:</h3>
                    
                  </div>
                  <hr style={{color:"antiquewhite"}}/>
                  <br />
                      <h4>Next Day Value  : {item.arima.nextDayPrediction}</h4>
                      <h4>Current Value  : {item.arima.currentDayValue}</h4>
                      <h4>Prediction  : {item.arima.prediction}</h4>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Info;
